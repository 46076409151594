<template>
  <div class="sector__card mb-48 sector__card--information">
    <tabs :options="{ useUrlFragment: false }">
      <tab name="नीतिहरु">
        <div v-if="policies.length">
          <div
            class="policies-parent"
            v-for="(ele, i) in policies"
            :key="`parent-${i}`"
          >
            <h3 class="mb-24">{{ ele.title }}</h3>
            <div v-if="ele.policies.length">
              <div
                class="policies-child mb-24"
                v-for="(elechild, j) in ele.policies"
                :key="`child-${j}`"
              >
                <h4 class="mb-16">{{ elechild.sub_title }}</h4>
                <div v-if="elechild.descriptions.length">
                  <ul>
                    <li
                      v-for="(elegrandchild, k) in elechild.descriptions"
                      :key="`grandchild-${k}`"
                      class="mb-16"
                    >
                      {{ `${englishToNepaliNumber(k + 1)}. `
                      }}{{ elegrandchild }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NoDataFound v-else />
      </tab>
      <tab name="नगरसभाका निर्णयहरु">
        <div v-if="decisions.length">
          <div
            class="decision-parent"
            v-for="(ele, i) in decisions"
            :key="`decision-${i}`"
          >
            <h3 class="mb-24">{{ ele.decision }}</h3>
            <ul>
              <li
                v-for="(elechild, j) in ele.decision_listings"
                :key="`decision-child-${j}`"
                class="mb-16"
              >
                {{ `${englishToNepaliNumber(j + 1)}. ` }}{{ elechild }}
              </li>
            </ul>
          </div>
        </div>
        <NoDataFound v-else />
      </tab>
      <tab name="कार्यक्षेत्रगत बजेट">
        <SectorTableData :bugetdetails="bugetdetails" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { Tabs, Tab } from "vue3-tabs-component";
import { englishToNepaliNumber } from "nepali-number";
import NoDataFound from "./../../components/NoDataFound.vue";

const SectorTableData = defineAsyncComponent(() =>
  import("../../components/sector/sectorTableData.vue")
);
export default {
  data() {
    return {
      englishToNepaliNumber,
    };
  },
  components: {
    Tab,
    Tabs,
    SectorTableData,
    NoDataFound,
  },
  props: {
    policies: { type: Array, required: false },
    decisions: { type: Array, required: false },
    bugetdetails: { type: Array, required: false },
    fiscalYear: { type: String, required: false },
  },
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
.sector__card--information {
  padding: 0;
  .tabs-component-tabs {
    margin-bottom: 32px;
    padding: 40px 32px 0;
    li {
      margin-right: 40px;
      padding: 0 10px 16px;
      width: auto;
      a {
        font-size: 16px;
        font-family: "Mukta-Bold";
      }
    }
  }

  .tabs-component-panels {
    padding: 0 40px 40px;
  }

  .tabs-component-panel p,
  .tabs-component-panel ul li {
    font-family: "Mukta-Medium";
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.policies-parent:not(:first-child) h3,
.decision-parent:not(:first-child) h3 {
  margin-top: 40px;
}
</style>
